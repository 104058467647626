import React, { Fragment } from 'react'
import * as _ from 'lodash'
import * as moment from 'moment'
import DocumentTitle from 'react-document-title'
import Select from 'react-select'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { withTranslation } from 'react-i18next'

// @material-ui/core
import { Icon, Grid, TextField } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import Autocomplete from '@material-ui/lab/Autocomplete'
import Popper from '@material-ui/core/Popper'

// core components
import GridContainer from 'components/Grid/GridContainer.jsx'
import GridItem from 'components/Grid/GridItem.jsx'
import Button from 'components/CustomButtons/Button.jsx'
import Loader from 'components/Loader/Loader.jsx'
import Section from 'components/Section'
import NoResults from 'components/NoResults/NoResults.jsx'
import GriTable from './Components/GriTable'
import adminNavbarLinksStyle from 'assets/jss/material-dashboard-pro-react/components/adminNavbarLinksStyle.jsx'

// Utils
import { history } from 'utils'
import compose from 'utils/compose'
import { numberFormatText } from 'utils/utils'
import styles from './campaign.module.scss'
import { germiniDefaultColors, grayColor } from 'assets/jss/material-dashboard-pro-react'
import { makeStyles, createStyles } from '@material-ui/core/styles'

import * as S from './style'

import extendedFormsStyle from 'assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.jsx'

// REDIX INIT
import { connectRouterState } from 'utils/connectRouterState'

import { SearchCampaignData, GetCampaignStatusListData, SweetAlert, HideAlert, ShowLoader, HideLoader } from 'redux/actions'

import { getThemeColorFromLocalStorage } from '../../redux/api/themeColors'
import { envs } from '../../redux/api/windowServerData'
import { getProgramTitle } from '../../utils/utils'

const getThemeColorStructureBackground = () => {
	if (getThemeColorFromLocalStorage()) {
		if (getThemeColorFromLocalStorage().structure) {
			if (getThemeColorFromLocalStorage().structure.backgroundColor) {
				return getThemeColorFromLocalStorage().structure.backgroundColor
			}
		}
	}
	return '#08993A'
}

const customSelectStyles = {
	control: (base, state) => ({
		...base,
		input: { height: '25px' },
		boxShadow: state.isFocused ? 0 : 0,
		borderColor: state.isFocused ? grayColor[2] : base.borderColor,
		'&:hover': {
			borderColor: state.isFocused ? grayColor[2] : base.borderColor,
		},
	}),
}

const CustomPopper = function(props) {
	const classesPoper = useStyles()
	return <Popper {...props} className={classesPoper.root} placement="bottom" />
}

const useStyles = makeStyles(theme =>
	createStyles({
		root: {
			'& .MuiAutocomplete-listbox': {
				'& :hover': {
					color: '#ffffff',
					backgroundColor:
						getThemeColorFromLocalStorage() && getThemeColorFromLocalStorage().structure && getThemeColorFromLocalStorage().structure.backgroundColor
							? getThemeColorFromLocalStorage().structure.backgroundColor
							: '#fff !important',
				},
				'& li': {
					backgroundColor: '#ffffff',
				},
			},
		},
	}),
)

const CssTextField = withStyles({
	root: {
		'& label.Mui-focused': {
			color: getThemeColorStructureBackground(),
		},
		'& label': {
			fontSize: '14px',
			lineHeight: 1.2,
		},
		'& .MuiInput-underline:after': {
			borderBottomColor: getThemeColorStructureBackground(),
		},
		'& .MuiOutlinedInput-root': {
			'&:hover fieldset': {
				borderColor: getThemeColorStructureBackground(),
			},
			'&.Mui-focused fieldset': {
				borderColor: getThemeColorStructureBackground(),
			},
		},
	},
})(TextField)

class Campaigns extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			results: false,
			campaignFailed: false,
			searchName: '',
			searchSponsor: '',
			searchStatus: true,
			statusOptions: [],
			currentPage: 1,
			pageSize: 0,
			recordCount: 0,
			campaignType: '',
			filtered: false,
			open: false,
			themeColor: getThemeColorFromLocalStorage(),
		}
	}

	componentDidMount() {
		this.setState({ themeColor: getThemeColorFromLocalStorage() })
		var currentUrl = window.location.pathname
		if (currentUrl.toString() === '/campaign/redeem') {
			this.setState({ campaignType: 2 })
		} else {
			this.setState({ campaignType: 1 })
		}

		this.props.ShowLoader(<Loader />)
		this.props.GetCampaignStatusListData().then(() => {
			this.setState({ ...this.props.urlState }, () => {
				this.props.SearchCampaignData(
					_.get(this.props.urlState, 'searchName', ''),
					_.get(this.props.urlState, 'searchSponsor', ''),
					_.get(this.props.urlState, 'searchStatus', ''),
					this.props.userData.id,
					this.state.campaignType,
					_.get(this.props.urlState, 'currentPage'),
				)
				//
				if (!_.isEmpty(this.state.searchName) || !_.isEmpty(this.state.searchSponsor) || !_.isEmpty(this.state.searchStatus)) {
					this.setState({ filtered: true })
				}
			})
		})
	}

	componentDidUpdate(prevProps, prevState) {
		let prevUrlState = _.get(prevProps, 'urlState')
		let urlState = _.get(this.props, 'urlState')
		if (!_.isEqual(prevUrlState, urlState)) {
			this.setState({
				...urlState,
				searchStatus: _.find(this.state.statusOptions, x => x.key === _.get(this.props.urlState, 'searchStatus', '')),
			})
			this.props.SearchCampaignData(
				urlState.searchName,
				urlState.searchSponsor,
				urlState.searchStatus,
				'',
				this.state.campaignType,
				urlState.currentPage,
			)
			window.scroll(0, 0)
		}

		if (prevProps.lsCampaignData !== this.props.lsCampaignData) {
			this.setState({ ...this.props.lsCampaignData }, () => this.props.HideLoader())
		}

		if (prevProps.campaignStatusList !== this.props.campaignStatusList) {
			this.setState({ statusOptions: this.setStatusOptionState() })
		}

		if (this.props.campaignFailed && prevProps.campaignFailed !== this.props.campaignFailed) {
			this.props.HideLoader()
			this.setState({ campaignFailed: true })
		}
	}

	handleChange = event => {
		const id = event.target.id || event.target.name
		const value = event.target.value
		this.setState({ [id]: value })
	}

	handleSelectedChange = (event, value, reason) => {
		this.setState({ searchStatus: value && value })
	}

	handleSearchPartner = currentPage => {
		this.props.ShowLoader(<Loader />)
		this.props.setURLState({ currentPage })
	}

	setStatusOptionState = () => {
		let list = []
		this.props &&
			this.props.campaignStatusList &&
			this.props.campaignStatusList.map(item =>
				list.push({
					key: item.key,
					value: item.value,
					label: this.props.t(item.value),
				}),
			)
		return list
	}

	getStatusOption = i => {
		const { statusOptions } = this.state
		let option = _.find(statusOptions, x => x.key === i.toString())
		let textLabel = option?.label

		switch (option?.value?.toLowerCase() ?? null) {
			case 'reproved':
				return (
					<div
						style={{
							display: 'flex',
							lineHeight: 0.8,
							marginTop: '4px',
						}}
					>
						<Icon
							className="icon-grid_reprovado"
							style={{
								fontSize: '15px',
								color: '#D60000',
								size: '11px',
								overflow: 'unset',
							}}
						/>
						<div style={{ marginTop: '3px', marginLeft: '7px' }}>{textLabel}</div>
					</div>
				)
			case 'finished':
				return (
					<div
						style={{
							display: 'flex',
							lineHeight: 0.8,
							marginTop: '4px',
						}}
					>
						<Icon
							className="icon-grid_finalizado"
							style={{
								fontSize: '15px',
								color: '#CAA22C',
								size: '11px',
								overflow: 'unset',
							}}
						/>
						<div style={{ marginTop: '3px', marginLeft: '7px' }}>{textLabel}</div>
					</div>
				)
			case 'expired':
				return (
					<div
						style={{
							display: 'flex',
							lineHeight: 0.8,
							marginTop: '4px',
						}}
					>
						<Icon
							className="icon-grid_expirado"
							style={{
								fontSize: '15px',
								color: '#8F48D6',
								size: '11px',
								overflow: 'unset',
							}}
						/>
						<div style={{ marginTop: '3px', marginLeft: '7px' }}>{textLabel}</div>
					</div>
				)
			case 'pending':
				return (
					<div
						style={{
							display: 'flex',
							lineHeight: 0.8,
							marginTop: '4px',
						}}
					>
						<Icon
							className="icon-ico_grid_pendente"
							style={{
								fontSize: '15px',
								color: '#E9CC38',
								size: '11px',
								overflow: 'unset',
							}}
						/>
						<div style={{ marginTop: '3px', marginLeft: '7px' }}>{textLabel}</div>
					</div>
				)
			case 'active':
				return (
					<div
						style={{
							display: 'flex',
							lineHeight: 0.8,
							marginTop: '4px',
						}}
					>
						<Icon
							className="icon-grid_ativo"
							style={{
								fontSize: '15px',
								color: '#9FB029',
								size: '11px',
								overflow: 'unset',
							}}
						/>
						<div style={{ marginTop: '3px', marginLeft: '7px' }}>{textLabel}</div>
					</div>
				)
			case 'inactive':
				return (
					<div
						style={{
							display: 'flex',
							lineHeight: 0.8,
							marginTop: '4px',
						}}
					>
						<Icon
							className="icon-grid_inativo"
							style={{
								fontSize: '15px',
								color: '#38B9E9',
								size: '11px',
								overflow: 'unset',
							}}
						/>
						<div style={{ marginTop: '3px', marginLeft: '7px' }}>{textLabel}</div>
					</div>
				)
			case 'interrupted':
				return (
					<div
						style={{
							display: 'flex',
							lineHeight: 0.8,
							marginTop: '4px',
						}}
					>
						<Icon
							className="icon-interromper"
							style={{
								fontSize: '15px',
								color: '#373435',
								size: '11px',
								overflow: 'unset',
							}}
						/>
						<div style={{ marginTop: '3px', marginLeft: '7px' }}>{textLabel}</div>
					</div>
				)
			default:
				return <div></div>
		}
	}

	handleFilter = clear => {
		const { searchName, searchSponsor, searchStatus } = this.state
		this.props.setURLState({
			searchName,
			searchSponsor,
			searchStatus: _.get(searchStatus, 'key', ''),
			currentPage: 1,
		})
		//
		if (clear) {
			this.setState({ filtered: false })
		} else {
			this.setState({ filtered: true })
		}
	}

	handleClearFilter = () => {
		this.setState(
			{
				filtered: false,
				searchName: '',
				searchSponsor: '',
				searchStatus: '',
			},
			() => this.handleFilter(true),
		)
	}

	handleClick = () => {
		this.setState({ open: !this.state.open })
	}
	handleClose = () => {
		this.setState({ open: false })
	}

	handleProfile = i => {
		history.push(`/${i}`)
		this.handleClose()
	}

	render() {
		const { classes, t } = this.props
		const { filtered, searchName, searchSponsor, searchStatus, results, statusOptions } = this.state

		return (
			<S.Container
				themeColor={
					getThemeColorFromLocalStorage() && getThemeColorFromLocalStorage().structure && getThemeColorFromLocalStorage().structure.backgroundColor
						? getThemeColorFromLocalStorage().structure.backgroundColor
						: '#08993A'
				}
			>
				<DocumentTitle title={getProgramTitle()} />
				<Grid style={S.titleStyles}>
					<Section title={t('MENU_CAMPAIGN')} style={{ flexGrow: 1 }} />
					<div style={{ marginLeft: '15px' }}>
						{this.state.campaignType.toString() === '1' ? (
							<Button size="sm" variant="contained" color="greenButtonColor" onClick={() => this.handleNew()}>
								{t('BUTTON_CREATE_CAMPAIGN')}
							</Button>
						) : (
							<Button size="sm" variant="contained" color="greenButtonColor" onClick={() => this.handleNewRedeem()}>
								{t('BUTTON_CREATE_CAMPAIGN')}
							</Button>
						)}
					</div>
				</Grid>
				<S.GroupContainer>
					<GridItem xs={12} sm={5} md={5}>
						<CssTextField
							className={styles.TextField}
							fullWidth
							label="Nome"
							id="searchName"
							name="searchName"
							value={searchName}
							onChange={this.handleChange}
							variant="outlined"
							size="small"
							style={{ backgroundColor: '#fff' }}
						/>
					</GridItem>
					<GridItem xs={12} sm={5} md={5}>
						<CssTextField
							className={styles.TextField}
							fullWidth
							label="Patrocinador"
							id="searchSponsor"
							name="searchSponsor"
							value={searchSponsor}
							onChange={this.handleChange}
							variant="outlined"
							size="small"
							style={{ backgroundColor: '#fff' }}
						/>
					</GridItem>
					<GridItem xs={12} sm={2} md={2}>
						<Autocomplete
							styles={customSelectStyles}
							id="combo-box-demo"
							size="small"
							options={statusOptions}
							getOptionLabel={option => (option.label ? option.label : '')}
							getOptionSelected={(option, value) => value === undefined || value === '' || option.id === value.id}
							disableClearable={true}
							onChange={this.handleSelectedChange}
							value={searchStatus}
							PopperComponent={CustomPopper}
							renderInput={params => (
								<div ref={params.InputProps.ref}>
									<TextField
										style={{
											backgroundColor: '#fff',
											width: '100%',
										}}
										{...params}
										noOptionsMessage={() => t('RESULTS_NOT_FOUND')}
										label={t('FIELD_STATUS')}
										variant="outlined"
										size="small"
									/>
								</div>
							)}
						/>
					</GridItem>

					<GridContainer>
						<S.GroupStylesButton
							xs={12}
							sm={12}
							md={12}
							style={{
								marginTop: '1.25rem',
							}}
						>
							<Button size="sm" variant="contained" color="greenBorderButtonColor" style={{ margin: 0 }} onClick={() => this.handleClearFilter()}>
								{t('BUTTON_CLEAN_FILTER')}
							</Button>{' '}
							<Button size="sm" variant="contained" color="greenButtonColor" style={{ margin: 0 }} onClick={() => this.handleFilter(false)}>
								{t('BUTTON_FIND')}
							</Button>
						</S.GroupStylesButton>
					</GridContainer>
				</S.GroupContainer>

				{/* - DATATABLE */}
				{filtered ? <Section title={t('TITLE_SEARCH_RESULTS')} /> : ''}
				<GridContainer justifyContent="center" className="gridContainerTable" style={{ paddingLeft: '15px' }}>
					{results && results.length > 0 ? (
						<Fragment>
							{results &&
								results.map((item, index) => [
									<GriTable
										key={index}
										id={item.id}
										campaignMode={item.campaignMode}
										history={this.props.history}
										labels={[
											t('FIELD_TABLE_CAMPAIGN_NAME'),
											t('FIELD_TABLE_SPONSOR'),
											t('FIELD_TABLE_TERM'),
											t('FIELD_TABLE_VALUE'),
											t('FIELD_STATUS'),
										]}
										values={[
											item.name,
											item.sponsor ? item.sponsor.name : '',
											item.endTerm
												? (moment(item.startTerm).isValid() ? moment(item.startTerm).format('L') : '-') +
												  ' - ' +
												  (moment(item.endTerm).isValid() ? moment(item.endTerm).format('L') : '-')
												: t('FIELD_TABLE_UNDEFINED'),
											item.investment && item.investment !== '0' ? (
												<Fragment>
													<span>
														{localStorage.getItem('currencySymbol')
															? localStorage.getItem('currencySymbol') + ' '
															: // : envs
															  // 		.REACT_APP_CURRENCY_SYMBOL +

															  envs.REACT_APP_CURRENCY_SYMBOL + ' '}
													</span>
													<span>{numberFormatText(item.investment, '', 2)}</span>
												</Fragment>
											) : (
												'-'
											),
											this.getStatusOption(item.status),
										]}
									/>,
								])}
						</Fragment>
					) : (
						<NoResults value={t('RESULTS_NOT_FOUND')} />
					)}
				</GridContainer>
			</S.Container>
		)
	}

	//
	handleNew = () => {
		this.props.history.push(`/campaigns/new`)
	}
	//
	handleNewRedeem = () => {
		this.props.history.push(`/campaign/newRedeemCampaign`)
	}

	//
	handleTableView = i => {
		if (i.campaignMode === 2) {
			this.props.history.push(`/campaign/viewRedeemCampaign/${i.id}`)
		} else {
			this.props.history.push(`/campaigns/view/${i.id}`)
		}
	}
	//

	//
	handleTablePerformance = i => {
		this.props.history.push(`/campaigns/performance/${i.id}`)
	}
}

const mapStateToProps = state => {
	return {
		lsCampaignData: state.campaign.lsCampaignData,
		campaignFailed: state.campaign.campaignFailed,
		campaignStatusList: state.campaign.campaignStatusList,
		userData: state.users.userData,
	}
}

const mapDispatchToProps = dispatch =>
	bindActionCreators(
		{
			SearchCampaignData,
			GetCampaignStatusListData,
			SweetAlert,
			HideAlert,
			ShowLoader,
			HideLoader,
		},
		dispatch,
	)

export default compose(
	withRouter,
	withTranslation(),
	connectRouterState({
		searchName: '',
		searchSponsor: '',
		searchStatus: '',
		currentPage: 1,
	}),
	withStyles(extendedFormsStyle),
	withStyles(adminNavbarLinksStyle),
	connect(
		mapStateToProps,
		mapDispatchToProps,
	),
)(Campaigns)
